.react-autosuggest__suggestions-list li {
  list-style: none;
  margin: 0px 0px;
  padding: 2px 17px;
}

.react-autosuggest__suggestions-list {
  padding-left: 0px;
  border: 1px solid var(--amplify-components-fieldcontrol-border-color);
}

.react-autosuggest__suggestions-list li:hover {
  background-color: var(--amplify-colors-background-disabled);
  cursor: pointer;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-list {
  border: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  border: 1px solid #aaa;
  background-color: #fff;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  max-height: 50vh;
  overflow-y: auto;
}
