.stickyNavBar {
  position: sticky;
  top: 0px;
  height: 100vh;
}

.container-fullscreen {
  margin-left: 15px;
  margin-right: 15px;
}
