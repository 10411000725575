td.currency, th.currency {
    text-align: right;
}

.sortField {
    margin-left: 10px;
}

.visible {
    visibility: visible;
}

.hidden {
    visibility: hidden;
}
