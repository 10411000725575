

.page_title {
font-size: x-large;
}

.component_title {
font-size: large;
}



.boxsizingBorder {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}




.editable {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    width: 100%;
    min-height: 100px;
    border: 1px solid #ced4da;
    padding: .375rem .75rem;
    resize: none;
}

.incident_name {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    width: 100%;
}

.component_status {
    width: 230px;
    float: right;
    margin-top: 0px;
    padding-top: 0px;
    display: flex
}



.frame-wrapper {
    display: flex;
    background-color:#ff7800ab; /* #E67E22;*/
    color: black;  /* #FFFFFF;*/
    margin: 0;
    padding: 0;
    width: 100%;
    /* width: 320px; */
    /* height: 150px; */
    border-radius: 3px;
}


.incident-title {
    word-wrap: break-word;
    margin: 0;
    width: 72vw;    
    max-height: 30vh;
    min-height: 4vh;
    overflow: hidden;
    font-size: 25px;
    color: red;
}

.incident-title p {
    font-family: sans-serif;
    padding-left: 5px;
}

.componentNotInAllPagesWarningMessage {
    display: none;
}

.componentNotInAllPagesWarning:hover + .componentNotInAllPagesWarningMessage {
    display: block;
    color: red;
}


.warningIcon {
    position: relative;
    display: inline-block;
    border-bottom: 5px dotted red; /* If you want dots under the hoverable text */
    color: red;
    margin-left: 10px;
}
  
.warningIcon .warningMessage {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}
  
/* Show the tooltip text when you mouse over the tooltip container */
.warningIcon:hover .warningMessage {
   visibility: visible;
}

.updateButton {
    border-bottom: 5px dotted green; /* If you want dots under the hoverable text */
    font-size: 20px;
}

.updateButtonWrapperDiv {
    padding-left: 0px;
    margin-left: 0px;
    margin-bottom: 16px;
}


.statusCardStatus {
    background: red;
    color: black;
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 25px;
    margin: 25px;
    height: 100px;
}

div.navbar {
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;

    margin-top: 10px;
    width: 200px;
    display: block;
}

.greeting {
    padding-bottom: 30px;
}

.signout {
    padding-top: 30px;
}

#incident-message textarea {
    overflow: hidden;
    overflow-wrap: break-word;
    height: 300px;
    width: 400px;
}

#create-buttton  {
    background: rgb(18, 103, 231);
    background-color: rgb(18, 103, 231);
    padding: 1px 6px;
}

#create-button:hover {
    background: rgb(7, 71, 166);
}


#incident-create-action {
    
    padding: 50px 0px 0px 50px;
}

.itemName {
    margin-left: 10px;
}

.investigatingStatusCardStatus {
    background: red;
    color: black;
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 25px;
    margin: 25px;
    height: 100px;
    width: 200px;
    text-align: center;
}
.identifiedStatusCardStatus {
    background: yellow;
    color: black;
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 25px;
    margin: 25px;
    height: 100px;
    width: 200px;
    text-align: center;
}

.monitoringStatusCardStatus {
    background: #007bff;
    color: black;
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 25px;
    margin: 25px;
    height: 100px;
    width: 200px;
    text-align: center;
}
.resolvedStatusCardStatus {
    background: green;
    color: black;
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 25px;
    margin: 25px;
    height: 100px;
    width: 200px;
    text-align: center;
}

.overlay {
    opacity:    0.4; 
    background: black; 
    width:      100%;
    height:     100%; 
    z-index:    10;
    top:        0; 
    left:       0; 
    position:   fixed; 
}

.loadingImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 25%;
}